<template>
  <div class="arrow">
    <div class="arrow__icon">
      <img src="../resource/sai-icon_scroll.gif" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ScrollGuideContent extends Vue {}
</script>

<style lang="scss" scoped>
@import '../style/component/scrollGuide';
</style>
