<template>
  <div class="sai-tag-list">
    <div class="sai-tag-list__head">
      <div class="sai-tag-list__head__title">
        <div class="sai-tag-list__head__title__text">
          {{ tagTitle }}
        </div>
      </div>
      <div class="sai-tag-list__tags" v-if="!$store.state.user.isSP">
        <a
          class="sai-tag-list__tags__tag"
          v-for="(candidate, index) in filteredCandidateTags"
          v-bind:key="index"
          v-if="index < 6 && candidate.tag"
          @click.prevent="selectTag(candidate)"
        >
          <span class="sai-tag-list__tags__tag__content">
            {{ convertTagText(candidate) }}
          </span>
        </a>
      </div>
    </div>
    <div class="sai-tag-list__tags" v-if="$store.state.user.isSP">
      <a
        class="sai-tag-list__tags__tag"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < 6 && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ convertTagText(candidate) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagListMixin from '@/mixins/TagListMixin';
import Const from '../const/index';

@Component({})
export default class TagList extends mixins(TagListMixin) {
  get tagTitle() {
    return this.inputEmpty ? Const.TAG_POPULAR_TITLE : Const.TAG_RECOMMEND_TITLE;
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagList';
</style>
