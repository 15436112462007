<template>
  <div>
    <div class="sai-tagged-input" :class="{ onFocus: show }">
      <div class="sai-tagged-input__contents">
        <a
          class="sai-tagged-input__contents__tag"
          :class="tag.type === 'node' && 'category'"
          v-for="tag in selectedTags"
          :key="tag.id"
        >
          {{ tag.displayText || tag.text }}
          <a
            class="sai-tagged-input__contents__tag__close"
            @click.prevent="removeTag(tag)"
            ><font-awesome-icon icon="times" />
          </a>
        </a>
        <input
          class="sai-tagged-input__contents__input"
          type="text"
          size="1"
          :value="searchText"
          :placeholder="placeholderMessage"
          @input="customUpdateSearchText($event.target.value)"
          @focus="onFocus"
          @keyup="keyupHandler"
          @keydown="keydownHandler"
          @keypress="onKeypress"
          @compositionstart="onCompositionStart"
          @compositionend="onCompositionEnd"
          ref="searchInput"
        />
      </div>
      <div class="sai-tagged-input__functions" v-show="show">
        <div
          class="sai-tagged-input__functions__calc"
          v-if="
            filteredItems.length > 0 && (selectedTags.length > 0 || searchText)
          "
        >
          <span class="sai-tagged-input__functions__calc__count">
            <span class="sai-tagged-input__functions__calc__count__num">
              {{ filteredItems.length }}
            </span>
            <span class="sai-tagged-input__functions__calc__count__text">
              件<span>に絞り込まれています</span>
            </span>
          </span>
          <div class="sai-tagged-input__functions__calc__bar">
            <div class="sai-calc-bar" :style="{ width: barWidth + 'px' }">
              <span
                class="sai-calc-bar inner"
                :style="{
                  width: calcBarWidth(filteredItems.length) + 'px',
                }"
              ></span>
            </div>
          </div>
        </div>
        <div class="sai-tagged-input__functions__border"></div>
        <div class="sai-tagged-input__functions__close">
          <a
            class="sai-tagged-input__functions__close__icon"
            @click.prevent="closeList"
          >
            <img src="../resource/sai-icon_slim_x.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TaggedInputMixin from '@/mixins/TaggedInputMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';
import dataResource from '@/common/dataResource';
import talkScript from '@/common/talkScript';

@Component({})
export default class TaggedInput extends mixins(TaggedInputMixin) {
  barWidth = 130;

  get noReccomend() {
    return (
      this.$store.state.tagSearch.filteredItems.length === 0 &&
      this.$store.state.tagSearch.selectedTags.values().length === 0 &&
      this.$store.state.tagSearch.candidateTags.length === 0
    );
  }

  get placeholderMessage() {
    return this.$store.state.tagSearch.selectedTags.values().length === 0
      ? Const.INITIAL_INPUT_MESSAGE
      : '';
  }

  async customUpdateSearchText(val) {
    if (val.toLowerCase().indexOf('faq') === -1) {
      this.updateSearchText(val);
    } else {
      await dataResource.ready({ resourceName: 'talkScript' });
      await talkScript.ready();
      let talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      talkScripts = talkScripts
        .filter(s => {
          const match = s.text.match(/\[([^\[\]]*)\]/);
          if (match) {
            const tsId = match[1];
            if (tsId.toLowerCase().indexOf(val.toLowerCase()) !== -1)
              return true;
          }
          return false;
        })
        .slice(0, 10);
      this.$store.commit('tagSearch/setSearchText', val);
      this.$store.commit('tagSearch/setFilteredItems', talkScripts);
    }
  }
  async onFocus() {
    if (this.show) {
      return;
    }
    this.setShow(true);
    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 300);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  calcBarWidth(count) {
    const length = count >= 100 ? this.barWidth : this.barWidth * (count / 100);
    return length > 2 ? length : 2;
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/taggedInput';
</style>
