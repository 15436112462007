<template>
  <ScrollGuide ref="scrollGuide">
    <div :class="['sai-resource-list', active && 'active']">
      <div class="wrapper">
        <div
          class="content"
          :class="
            item &&
              item.viewType !== 'result' &&
              isTitle(item.caption) &&
              'title'
          "
        >
          <div class="icon"></div>
          <div class="captionWrapper">
            <div
              class="caption"
              v-show="item && item.caption"
              v-html="item && item.caption"
            ></div>
          </div>
        </div>
        <div ref="items" class="items">
          <div
            :class="['item', isSelected(choice) && 'selected']"
            v-for="(choice, index) in list"
            :key="index"
          >
            <div
              class="itemContent"
              :id="`${choice.stepId}_${index}`"
              @click.prevent="openHandler(choice)"
            >
              {{ choice.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="feedbackArea">
        <ResourceListFooter
          :currentValue="currentValue"
          :lastScript="lastScript"
          :withEnquete="feedbackWithEnquete"
          :withSuggestion="feedbackWithSuggestion"
        />
      </div>
    </div>
  </ScrollGuide>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import { eventBus } from '@/eventBus';
import Const from '../const/index';

@Component({})
export default class Scenario extends mixins(ResourceListMixin) {
  feedbackWithEnquete = Const.FEEDBACK_WITH_ENQUETE;
  feedbackWithSuggestion = Const.FEEDBACK_WITH_SUGGESTION;

  get talkScriptIndex() {
    return this.$store.getters['navigation/getRoutes'].filter(r => {return r.talkScriptId}).length - 1;
  }
  async openHandler(item) {
    this.open(item);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  open(item) {
    this.$store.commit('navigation/open', {
      index: this.index + this.talkScriptIndex,
      route: item,
    });
  }
  updateRelation() {}
}
</script>

<style lang="scss" scoped>
@import '../style/component/scenario';
</style>
