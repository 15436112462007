<template>
  <div class="sai-enquete"
    :class="{
      single: singleEnquete,
      ie: $store.state.user.isIE
    }"
  >
    <div class="inner">
      <div class="wrapper" v-if="!done" :style="{maxHeight: height()}">
        <div class="head">
          <div class="closeButton">
            <a @click="close"><font-awesome-icon icon="times" /></a>
          </div>
          <div class="title">{{ heading }}</div>
          <div class="description">{{ description }}</div>
          <div class="alert" v-if="invalidValues">{{ alertMessage }}</div>
        </div>
        <div class="body">
          <div class="content">
            <div class="questions">
              <div
                class="question"
                v-for="(item, idx) in enquete"
                :key="item.index"
              >
                <div class="title">
                  <div class="icon">{{ 'Q' + item.index }}</div>
                  <div class="text">
                    <span class="tag" v-if="item.required">[必須]</span>
                    <span>{{ item.title }}</span>
                  </div>
                </div>
                <div class="description">{{ item.description }}</div>
                <div class="textarea" v-if="item.type === 'textarea'">
                  <textarea
                    type="text"
                    rows="8"
                    :placeholder="item.placeholder"
                    @input="updateInputText(item.index, $event.target.value)"
                  />
                </div>
                <div v-else :class="item.type">
                  <div
                    class="choice"
                    v-for="(c, c_idx) in item.choices"
                    :key="c_idx"
                  >
                    <input
                      :type="item.type"
                      :id="getChoiceId(item.index, c_idx)"
                      :name="getChoiceName(item.index)"
                      :value="c.value"
                      @change="updateSelectedChoices(
                        $event.target.checked,
                        item.index,
                        item.type,
                        c.value,
                        c.label
                      )"
                    />
                    <label :for="getChoiceId(item.index, c_idx)">{{ c.label }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <a class="button send" @click="send">{{ sendText }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper completed" v-if="done">
        <div class="body">
          <div class="content">
            <div class="message" v-html="message"></div>
            <div class="buttons">
              <a class="button close" @click="close">{{ closeText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';
import Const from '../const/index';

@Component({
  props: {
    type: String,
    action: String,
    item: Object,
  },
})
export default class Enquete extends Vue {
  // データ
  enquete = [];
  values = {};
  // フラグ
  invalidValues = false;
  done = false;
  singleEnquete = false;
  // 文言
  heading = '';
  description = '';
  message = '';
  sendText = Const.ENQUETE_SEND_TEXT;
  closeText = Const.ENQUETE_CLOSE_TEXT;
  alertMessage = Const.ENQUETE_ALERT_MESSAGE;
  // その他
  prefix = 'sai_en_';

  height() {
    return (this.$store.state.user.isSP && window.innerHeight)
      ? `${window.innerHeight - 40}px`
      : 'calc(100vh - 40px)';
  }

  // ラジオボタン、チェックボックの属性値の設定
  getChoiceId(item_idx, choice_idx) {
    return this.prefix + item_idx + '_' + (choice_idx + 1);
  }
  getChoiceName(item_idx) {
    return this.prefix + item_idx;
  }

  // ラジオボタン、チェックボックの値の更新
  updateSelectedChoices(checked, index, type, value, label) {
    switch (type) {
      case 'radio':
        this.values[`${index}`].value = {
          value: value,
          label: label
        };
        return;
      case 'checkbox':
        const exValue = this.values[`${index}`].value.concat();
        this.values[`${index}`].value = checked
          ? [...exValue, {value: value, label: label}]
          : exValue.filter(c => c.value !== value);
    }
  }
  // textare の値の更新
  updateInputText(index, text) {
    this.values[`${index}`].value = text;
  }

  // アンケート閉じる
  close() {
    this.$emit('resetEnquete');
  }
  // アンケート送る
  async send() {
    if (!this.valid(this.values)) {
      this.invalidValues = true;
      return;
    }
    try {
      this.$store.commit('ticket/setResetFlag', true);
      const newTicket = await this.updateTicket();
      await this.$ticket.setData(newTicket);
      this.$ticket.post();
      this.done = true;
    } catch (e) {
      console.log(e);
      // Should add error handling
    }
  }
  // 必須事項のvalidation
  valid(values) {
    if (this.requiredIds.length === 0) return true;

    return this.requiredIds.every(i => {
      return this.exist(values[i].value);
    });
  }
  get requiredIds() {
    return this.enquete.filter(e => e.required).map(e => e.index);
  }
  exist(value) {
    const valueType = this.typeOf(value);
    switch (valueType) {
      case 'Array':
        return values.length > 0;
      case 'object':
        return Object.keys(value).length > 0;
      default:
        !!value;
    }
  }
  typeOf(value) {
    var toString = Object.prototype.toString;
    return toString.call(value).slice(8, -1).toLowerCase();
  }
  updateTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }

    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status_enquete: 'done',
    };

    let customTicket = '';
    switch (this.action) {
      case Const.ENQUETE.ACTION.SEARCH_FAILED:
        customTicket = { status: 'searchFailed' };
        break;
      case Const.ENQUETE.ACTION.SEARCH_NO_SCRIPT:
        customTicket = { status: 'searchNoScript' };
        break;
      case Const.ENQUETE.ACTION.FEEDBACK_RESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'resolved',
        };
        break;
      case Const.ENQUETE.ACTION.FEEDBACK_UNRESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'unresolved',
        };
        break;
      default:
        customTicket = { status: 'answered' };
    }
    const enquete = this.getEnqueteTicketData(this.type, this.values);

    let newTicket = {
      ...commonTicket,
      ...customTicket,
      ...enquete,
    };
    if (this.item) {
      newTicket.items = this.item.items;
    }
    return newTicket;
  }
  getEnqueteTicketData(type, values) {
    const key = `enquete_${type}`;
    return {[key]: values};
  }

  // 文言の初期設定
  fetchConst(type) {
    if (type === Const.ENQUETE.TYPE.RESOLVED) {
      this.heading = Const.HEADING_FEEDBACK_MESSAGE.RESOLVED;
      this.description = Const.ENQUETE_RESOLVED_DESCRIPTION;
      this.message = Const.ENQUETE_RESOLVED_MESSAGE;
      return;
    }
    this.heading = Const.HEADING_FEEDBACK_MESSAGE.UNRESOLVED;
    this.description = Const.ENQUETE_UNRESOLVED_DESCRIPTION;
    this.message = Const.ENQUETE_UNRESOLVED_MESSAGE;
  }

  // 表示アンケートデータの用意
  fetchEnquete(type) {
    const enquete =
      type === Const.ENQUETE.TYPE.RESOLVED
        ? Const.ENQUETE_SET.RESOLVED
        : Const.ENQUETE_SET.UNRESOLVED;
    this.enquete = enquete.sort((a,b) => {
      if(a.index < b.index) return -1;
      if(a.index > b.index) return 1;
      return 0;
    });
  }

  // チケット用アンケートデータの用意
  fetcEnqueteValues() {
    const values = this.enquete.reduce((values, {index, type, title}, idx) => {
      values[`${index}`] = {
        type: type,
        label: title,
        value: this.getInitialValue(type),
        index: Number(index)
      };
      return values;
    }, {});
    this.values = values;
  }
  getInitialValue(type) {
    switch (type) {
      case 'radio':
        return {};
      case 'checkbox':
        return [];
      default:
        return '';
    }
  }
  setSingleEnquete(enquete) {
    this.singleEnquete = enquete.length === 1;
  }
  async mounted() {
    this.fetchConst(this.type);
    await this.fetchEnquete(this.type);
    this.fetcEnqueteValues(this.type);
    this.setSingleEnquete(this.enquete);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/enquete';
</style>
