<template>
  <div
    class="FaqClientWindow mobile"
    ref="faqClientTag"
    :style="{ height: windowHeight }"
  >
    <div
      class="head"
      :class="withInitialForm && 'initialForm'"
      v-if="showDefaultHeader"
    >
      <div class="section">
        <a class="logo" v-if="showHeaderIcon" @click.prevent="customClear()">
          <img src="../resource/logo.png" />
        </a>
        <div class="title" v-if="!withInitialForm && showHeaderTitle">
          <div class="text">{{ mainTitle }}</div>
        </div>
      </div>
      <div class="section" v-if="withInitialForm && userId">
        <div class="user_info">
          <div class="text">{{ userInfo }}</div>
        </div>
        <a class="logout" @click.prevent="removeUserId()">
          <font-awesome-icon icon="sign-out-alt" />
          <span>{{ logOutText }}</span>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="search" ref="taggedInput" id="sai-tag-search-input">
        <TaggedInput
          :selectedTags="selectedTags.values()"
          :searchText="searchText"
          :inputEmpty="inputEmpty"
          :filteredItems="filteredItems"
          @onTagRemoved="removeSelectedTag"
          @onSearchTextChanged="setSearchText"
          @setShow="setTagSearchShow"
        />
      </div>
    </div>
    <div class="main">
      <div class="recommend" v-show="$store.state.tagSearch.show">
        <div ref="tagList">
          <TagList
            :candidateTags="candidateTags"
            :tagListTop="tagListTop"
            :inputEmpty="inputEmpty"
            :searchText="searchText"
            @onSelectTag="addSelectedTag"
            @setShow="setTagSearchShow"
          />
        </div>
        <div class="autocomplete custom-list">
          <Autocomplete
            v-if="!inputEmpty"
            :filteredItems="filteredItems"
            @selected="selectItem"
            @setTagSearchShow="setTagSearchShow"
          />
          <Frequent v-if="inputEmpty" @setTagSearchShow="setTagSearchShow" />
        </div>
      </div>
      <ColumnNavigation />
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import ProductFaqClientTagMixin from '../mixins/ProductFaqClientTagMixin';

@Component({})
export default class FaqClientWindow extends mixins(
  FaqClientTagMixin,
  ProductFaqClientTagMixin
  ) {

  scrollContainer = 'body';
  scrollTopElement = '.FaqClientWindow';
  scrollFaqElement = '#sai-scenario';
  scrollAnswerElement = '#sai-scenario-answer';
  scrollSearchElement = '#sai-tag-search-input';
  autoScrollElement = '#sai-tag-search-input';
  iphoneTopElement = '.FaqClientWindow';

  windowHeight = '100vh';

  autoScroll() {
    if (this.$store.state.user.isIphone) {
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      this.scrollTo(this.autoScrollElement, {
        container: this.scrollContainer,
        x: false,
        duration: 500,
      });
    }
  }
  setHeight() {
    this.windowHeight = `${window.innerHeight}px`;
  }
  created() {
    setTimeout(() => {
      this.setHeight();
    }, 0);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/faqClientWindow';
</style>
