<template>
  <div class="FaqClientWrapper" :class="$store.state.user.isSP && 'mobile'">
    <Loading :isLoading="isLoading">
      <FaqClientPage v-if="componentType === 'pc'" ref="faqClient" />
      <FaqClientWindow v-if="componentType === 'sp'" ref="faqClient" />
      <FaqClientMultiple v-if="componentType === 'both'" ref="faqClient" />
      <Modal
        v-if="withInitialForm"
        :click="false"
        :animation="false"
        :show="!$store.state.user.Id"
      >
        <InitialForm
          @updateUserId="updateUserId"
          @issueInitialTicket="issueInitialTicket"
        />
      </Modal>
      <Modal
        v-if="withEnquete"
        :click="false"
        :animation="true"
        :show="$store.state.enquete.show"
        @closeModal="resetEnquete"
      >
        <Enquete
          :type="$store.state.enquete.type"
          :action="$store.state.enquete.action"
          :item="$store.state.enquete.item"
          @resetEnquete="resetEnquete"
        />
      </Modal>
    </Loading>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientWrapperMixin from '@/mixins/FaqClientWrapperMixin';
import isEqual from 'lodash/isEqual';
import dataResource from '@/common/dataResource';
import { extractQueryParams } from '@/common/uriUtil';
import { Watch } from 'vue-property-decorator';
import { fromRoute, toRoute } from '@/common/resourceNavigationUtil';
import { eventBus } from '@/eventBus';
import Const from '../const/index';

@Component({})
export default class FaqClientWrapper extends mixins(FaqClientWrapperMixin) {
  withInitialForm = Const.WITH_INITIAL_FORM;
  withEnquete = Const.WITH_ENQUETE;

  get userId() {
    return this.$store.state.user.Id;
  }
  async updateUserId(id) {
    await this.$store.dispatch('user/updateId', id);
  }
  async resetEnquete() {
    await this.$store.dispatch('enquete/reset');
  }
  created() {
    if (!Const.ROUTING) {
      return;
    }
    this.$router.afterEach((route, from) => {
      this.$store.dispatch('history/add', route.params);
    });
    if (this.isHashRouting) {
      window.addEventListener('hashchange', (e) => {
        if (!this.$store.state.user.isIE) {
          return;
        }
        this.cuntomHashRouting();
      }, false);
    }
  }
  @Watch('$route.params', { immediate: true })
  async onRouteParamsChanged(params, oldParams) {
    if (!Const.ROUTING) {
      this.autoScroll();
      return;
    }
    if (isEqual(this.nextRouteParams, params)) {
      console.log('cancel update params');
      return;
    }
    console.log(
      "@Watch('$route.params')",
      params,
      oldParams,
      this.nextRouteParams
    );
    const route = await fromRoute(params);

    if (route.talkScript) {
      const routes = [];
      for (let current = route.talkScript; current; current = current.parent) {
        if (current.viewType === 'scenario') {
          const scenario = await dataResource.getItem(current);
          Object.assign(current.items, scenario.items);
        }
        routes.unshift(current);
      }
      routes.push.apply(routes, route.scenarios);
      this.$store.commit('navigation/setRoutes', routes);
    }

    if (Const.VARIABLE_META_DATA) {
      this.updateMetaData();
    }
    if (this.isReady && this.isHashRouting) {
      this.issueInitialTicket();
      if (!this.$store.state.user.isSP) {
        setTimeout(() => {
          eventBus.$emit('scrollToTop');
        }, 0);
      }
    }
    this.autoScroll();
  }
  @Watch('$store.state.navigation.routes')
  @Watch('$store.state.navigation.index')
  onNavigationChanged(state, oldState) {
    if (!Const.ROUTING) {
      this.autoScroll();
      return;
    }
    const params = toRoute(
      this.$store.getters['navigation/getEfficientRoutes']
    );
    if (isEqual(this.$route.params, params)) {
      console.log('cancel route update');
      return;
    }
    this.nextRouteParams = params;

    const route = { params };
    if (this.isHistoryRouting) {
      const query = extractQueryParams(document.location.search);
      console.log("@Watch('$store.state.navigation')", query);
      route.query = query;
    }
    console.log(
      "@Watch('$store.state.navigation')",
      this.$route.params,
      params,
      route.query || '',
    );
    this.$router.push(route);

    if (Const.VARIABLE_META_DATA) {
      this.updateMetaData();
    }
    this.autoScroll();
  }
  get isHashRouting() {
    return Const.ROUTING_MODE === Const.ROUTING_MODES.HASH;
  }
  get isHistoryRouting() {
    return Const.ROUTING_MODE === Const.ROUTING_MODES.HISTORY;
  }
  get isLoading() {
    if (Const.CHECK_INITIAL_INPUT) {
      return !this.isReady || !this.checkedUrlParams;
    }
    return !this.isReady;
  }
  @Watch('isReady')
  async initialActions() {
    if (!Const.ROUTING) {
      return;
    }
    if (Const.CHECK_INITIAL_INPUT) {
      await this.invokeInitialInputData();
      this.existInput && await this.setTagSearchShow(true);
      await this.setCheckedUrlParams(true);
    }
    if (Const.WITH_INITIAL_FORM && !this.$store.state.user.Id) {
      return;
    }
    await this.issueInitialTicket();
  }
  updateMetaData() {
    let baseUrl = Const.META_DATA.URL + this.$router.options.base.slice(1);

    const targetRoutes = this.$store.state.navigation.routes.filter(r => {
      return r.viewType === 'result' || r.viewType === 'scenario';
    });

    if (targetRoutes.length === 0) {
      this.setMetaData(
        baseUrl,
        Const.META_DATA.TITLE,
        Const.META_DATA.DESCRIPTION,
      );
      return;
    }

    const url = baseUrl + this.$route.path.slice(1);
    let title = '';
    const attach = Const.META_DATA.TITLE_ATTACH;
    for (var i in targetRoutes) {
      title += i > 0 ? attach + targetRoutes[i].text : targetRoutes[i].text;
    }
    title += Const.META_DATA.FIX_TITLE_TIP;
    const answer = targetRoutes[targetRoutes.length - 1];
    const description =
      answer.text +
      ' | ' +
      answer.caption
        .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
        .substr(0, 400);
    this.setMetaData(url, title, description);
  }
  setMetaData(url, title, description) {
    // normal
    document.querySelector("link[rel='canonical']").setAttribute('href', url);
    document.title = title;
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', description);

    // OG
    // document
    //   .querySelector("meta[property='og:title']")
    //   .setAttribute('content', title);
    // document
    //   .querySelector("meta[property='og:description']")
    //   .setAttribute('content', description);
    // document
    //   .querySelector("meta[property='og:url']")
    //   .setAttribute('content', url);
  }
}
</script>

<style lang="scss" scoped>
</style>
