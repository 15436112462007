<template>
  <div
    class="resolveEnquete"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div>
      <div class="resolveButtonArea">
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div v-show="showLastMessage">
      <div class="lastMessage caption" v-html="afterFeedbackMessage"></div>
      <div class="resolveButtonArea reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    withEnquete: Boolean,
    withSuggestion: Boolean,
  }
})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin
) {
  resolveMessage = Const.ASK_FEEDBACK_CHOICES.RESOLVED;
  unsolvedMessage = Const.ASK_FEEDBACK_CHOICES.UNRESOLVED;
  afterResulvedMessage = Const.AFTER_RESOLVED_MESSAGE;
  afterUnResulvedMessage = Const.AFTER_UNRESOLVED_MESSAGE;
  goToTopLabel = Const.GO_TO_TOP_LABEL;

  get afterFeedbackMessage() {
    if (this.feedbackResult === 'unresolved') {
      return this.afterUnResulvedMessage;
    }
    return this.afterResulvedMessage;
  }

  async resolved() {
    this.feedbackResult = 'resolved';

    if (this.withEnquete) {
      this.$store.dispatch('enquete/updateContents', {
        type: Const.ENQUETE.TYPE.RESOLVED,
        action: Const.ENQUETE.ACTION.FEEDBACK_RESOLVED,
        item: this.currentValue
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'resolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async unresolved() {
    this.feedbackResult = 'unresolved';

    if (this.withSuggestion) {
      this.$store.dispatch('enquete/updateContents', {
        type: Const.ENQUETE.TYPE.UNRESOLVED,
        action: Const.ENQUETE.ACTION.FEEDBACK_UNRESOLVED,
        item: this.currentValue
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'unresolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }

  async reset(e) {
    eventBus.$emit('resetWithScrollTop');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceListFooter';
</style>
