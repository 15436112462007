<template>
  <div class="sai-list-content">
    <ul class="sai-list-content__lists">
      <li
        class="sai-list-content__lists__item"
        v-for="(item, index) in list"
        v-if="item"
        :key="item.talkScriptId"
        :style="{ animationDelay: `${index < 20 ? 0.05 * (index + 1) : 0}s` }"
        @click="selectItem(item)"
      >
        <i class="sai-list-content__lists__item__top_icon"></i>
        <div class="sai-list-content__lists__item__wrapper">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">&gt;</span>
              </span>
            </div>
            <div class="sai-list-content__lists__item__content__title">
              <div class="sai-list-content__lists__item__content__title__text">
                {{ cleanText(item.text) }}
              </div>
            </div>
          </div>
          <div
            class="sai-list-content__lists__item__category"
            v-if="existsFaqNo(item.text)"
          >
            <div class="sai-list-content__lists__item__category__text">
              <font-awesome-icon icon="tag" /> {{ generateFaqNo(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__wrapper sp">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">&gt;</span>
              </span>
            </div>
            <div
              class="sai-list-content__lists__item__category"
              v-if="existsFaqNo(item.text)"
            >
              <div class="sai-list-content__lists__item__category__text">
                <font-awesome-icon icon="tag" /> {{ generateFaqNo(item.text) }}
              </div>
            </div>
          </div>
          <div class="sai-list-content__lists__item__content__title">
            <div class="sai-list-content__lists__item__content__title__text">
              {{ cleanText(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__icon">
          <font-awesome-icon icon="chevron-right" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ListContentMixin from '@/mixins/ListContentMixin';
import textParser from '../libs/textParser';

@Component({})
export default class ListContent extends mixins(ListContentMixin) {
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/listContent';
</style>
